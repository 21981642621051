var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"privacy-policy py-12"},[_c('v-container',[_c('div',{staticClass:"wrap"},[_c('div',{staticClass:"page-container"},[_c('div',{staticClass:"content-width-limit"},[_c('div',{staticClass:"page-header"},[_c('h1',[_vm._v(_vm._s(_vm.$t('privacyPol.title')))]),_c('h6',[_vm._v(_vm._s(_vm.$t('privacyPol.date')))])]),_c('div',{staticClass:"content"},_vm._l((_vm.items),function(item){return _c('div',{key:item},[(
                  item.includes('paragraph') ||
                  item.includes('sentence') ||
                  item.includes('footer')
                )?_c('p',[_vm._v(" "+_vm._s(_vm.$t(("privacyPol." + item)))+" "),_c('a',{directives:[{name:"show",rawName:"v-show",value:(
                    _vm.$t(("privacyPol." + item)).includes('at:') ||
                    _vm.$t(("privacyPol." + item)).includes('to:')
                  ),expression:"\n                    $t(`privacyPol.${item}`).includes('at:') ||\n                    $t(`privacyPol.${item}`).includes('to:')\n                  "}],attrs:{"href":"mailto:support@genesyswallet.io","rel":"noopener noreferrer"}},[_vm._v(" "+_vm._s(_vm.$t('common.support-email'))+" ")])]):(item.includes('item'))?_c('p',[_vm._v(" "+_vm._s(item[item.length - 1])+". "+_vm._s(_vm.$t(("privacyPol." + item)))+" ")]):(item.includes('subtitle'))?_c('h3',[_vm._v(" "+_vm._s(_vm.$t(("privacyPol." + item)))+" ")]):_c('h3',[_vm._v(" "+_vm._s(_vm.$t(("privacyPol." + item)))+" ")])])}),0)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }